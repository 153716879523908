"use client";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React from "react";

export default function DesktopNavigation() {
  const router = useRouter();
  return (
    <div className="desktop-navigation">
      <div className="navigation-logo">
        <Image
          src="/logo.png"
          alt="Kodiak Logo"
          width={1920}
          height={1080}
          style={{ cursor: "pointer" }}
          onClick={() => router.push("/")}
          priority
        />
      </div>
      <div className="navigation-links">
        <Link href="/">Home</Link>
        <Link href="/models">Products</Link>
        <Link href="/videos">Videos</Link>
        <Link href="/gallery">Images</Link>
        <Link href="/support">Support</Link>
        <Link href="/contact">Contact Us</Link>
        <Link href="/about">About</Link>
      </div>
      <div className="navigation-cta">
        <Link href="https://www.portal.rpscorporation.com/" target="_blank">
          Login
        </Link>
        {/* Kodiak phone */}
        <Link href="tel:1-855-965-0011">1-855-965-0011</Link>
      </div>
    </div>
  );
}
