"use client";
import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

export default function Tawk() {
  return (
    <TawkMessengerReact
      propertyId="662f98e11ec1082f04e8ac4a"
      widgetId="1hskuqceu"
    />
  );
}
