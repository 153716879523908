"use client";
import Image from "next/image";
import React, { useState } from "react";
import { MdMenu } from "react-icons/md";
import MobileMenu from "./MobileMenu";
export default function MobileNavigation() {
  const [showMenu, setShowMenu] = useState(false);
  const closeMenu = () => {
    console.log("Called");
    setShowMenu(false);
  };
  return (
    <div className="mobile-navigation-wrapper">
      <div className="mobile-navigation">
        <div className="logo-wrapper">
          <Image
            src="/logo.png"
            alt="Kodiak Logo"
            width={1920}
            height={1080}
            priority
          />
        </div>
        <div
          onClick={() => {
            console.log("clicked");
            setShowMenu(!showMenu);
          }}
        >
          <MdMenu />
        </div>
      </div>
      <div
        className={
          showMenu ? `mobile-menu-wrapper open` : `mobile-menu-wrapper`
        }
      >
        <MobileMenu close={closeMenu} />
      </div>
    </div>
  );
}
