import Link from "next/link";
import React from "react";
import { MdArrowForwardIos } from "react-icons/md";

export default function MobileMenu(props) {
  const close = props.close;
  return (
    <div className="mobile-menu">
      <ul>
        <li>
          <Link href="/" onClick={() => close()}>
            Home
          </Link>
          <MdArrowForwardIos />
        </li>
        <li>
          <Link href="/models" onClick={() => close()}>
            Products
          </Link>
          <MdArrowForwardIos />
        </li>
        <li>
          <Link href="/videos" onClick={() => close()}>
            Videos
          </Link>
          <MdArrowForwardIos />
        </li>
        <li>
          <Link href="/gallery" onClick={() => close()}>
            Gallery
          </Link>
          <MdArrowForwardIos />
        </li>
        <li>
          <Link href="/about" onClick={() => close()}>
            About
          </Link>
          <MdArrowForwardIos />
        </li>
        <li>
          <Link href="/demo" onClick={() => close()}>
            Request a Demo
          </Link>
        </li>
        <li>
          <Link href="/support" onClick={() => close()}>
            Support
          </Link>
        </li>
        <li>
          <Link href="/contact" onClick={() => close()}>
            Contact Us
          </Link>
        </li>
        <li>
          <Link
            href="https://www.portal.rpscorporation.com/"
            target="_blank"
            onClick={() => close()}
          >
            Login
          </Link>
        </li>
      </ul>
    </div>
  );
}
